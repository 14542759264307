import React from 'react';
import './TileGrid.css';
import { Tile } from '../tile/Tile';

export const TileGrid = ({ tiles, homepageCallback }) => {
  return (
    <div className='grid-wrapper'>
      <div className='grid-container'>
        {tiles.map((tile, index) => (
          <Tile
            key={index}
            homepageCallback={homepageCallback}
            // TODO: Determine the logic for what constitutes a 'big' tile
            size={index % 3 === 0 ? 'big' : 'small'}
            image={
              tile.thumbnailUrl === null
                ? 'https://lookaround-live.s3.amazonaws.com/ef6c96ed393b4081b5577b3fc0406aa5_8B1A7111.JPG'
                : tile.thumbnailUrl
            }
            isPremium={tile.isPremium}
            channelId={tile.channelId}
            latitude={tile.latitude}
            longitude={tile.longitude}
            title={tile.channelName}
            description={tile.description}
          />
        ))}
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import { ResponsiveBox } from '../responsive_box/ResponsiveBox';
import { ChannelNameButton } from './ChannelNameButton';
import { utc_time_string_to_local_time_string } from '../utilities';
import { LockedSymbol } from '../svg/LockedSymbol';
import { SendButton } from '../svg/sendButton/SendButton';
import { VerifiedChannel } from '../svg/VerifiedChannel';
import { fetchExperienceDetails } from '../../api/channel_api';

export const ChannelList = ({ channels, homepageCallback }) => {
  const children_per_channel = channels.map(channel => {
    return [
      {
        leftChild: (
          <div style={{ marginBottom: '10px', marginTop: '10px' }}>
            {channel.needs_passkey ? (
              // Only display the channel name- passkey entered in the right child.
              channel.channel_name
            ) : (
              // Make the channel name a button to fetch details.
              <ChannelNameButton homepageCallback={homepageCallback} channel={channel} />
            )}
          </div>
        ),
        rightChild: <ChannelRowRightColumn channel={channel} homepageCallback={homepageCallback} />,
        leftWidth: '66%',
        rightWidth: '34%',
      },
    ];
  });
  const children = children_per_channel.flatMap(list => list);

  return (
    <div>
      {channels.length === 0 && (
        <ResponsiveBox
          children={[
            {
              leftChild: (
                <div>
                  Complete experiences will be available here, so you can enjoy them later. Get started by tapping
                  LookAround.{' '}
                </div>
              ),
            },
          ]}
        />
      )}
      {channels.length > 0 && <ResponsiveBox children={children} />}
    </div>
  );
};

const ChannelRowRightColumn = ({ channel, homepageCallback }) => {
  const [enteredPasskey, setEnteredPasskey] = useState('');
  const [requestChannelState, setRequestChannelState] = useState('');

  const handlePasskeyChange = event => {
    setRequestChannelState('');
    setEnteredPasskey(event.target.value);
  };

  if (channel.is_curated) {
    return <VerifiedChannel />;
  } else if (!channel.needs_passkey) {
    return (
      <div style={{ fontSize: '12px' }}>
        {utc_time_string_to_local_time_string(channel.creation_time_utc_epoch_seconds)}
      </div>
    );
  } else if (channel.needs_passkey) {
    return (
      <div style={{ fontSize: '14px' }}>
        {channel.is_curated && <VerifiedChannel />}
        {(requestChannelState === '' || requestChannelState === 'failed') && (
          <input
            type='text'
            id='enteredPasskey'
            value={enteredPasskey}
            onChange={handlePasskeyChange}
            className='italic-placeholder'
            style={{
              width: '40%',
              padding: '5px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              boxSizing: 'border-box',
              fontSize: '14px',
            }}
            placeholder='Passkey'
          />
        )}
        {enteredPasskey !== '' && (requestChannelState === '' || requestChannelState === 'failed') ? (
          <SendButton
            onClick={async () => {
              if (enteredPasskey.length > 20) {
                setRequestChannelState('failed');
                return;
              }
              setRequestChannelState('requesting');
              fetchExperienceDetails(channel.channel_id, enteredPasskey).then(details => {
                if (details.statusCode === 400 || details.statusCode === 404) {
                  homepageCallback({ callbackType: 'signout', payload: {} });
                } else if (details.statusCode === 200) {
                  setRequestChannelState('');
                  homepageCallback({ callbackType: 'channelDetails', payload: details });
                } else if (details.statusCode === 403) {
                  setRequestChannelState('failed');
                } else {
                  throw new Error(`Error ${details.statusCode}: Something unexpected happened`);
                }
              });
            }}
          />
        ) : (
          <LockedSymbol />
        )}
        {requestChannelState === 'requesting' && <span style={{ fontSize: '12px' }}>Verifying...</span>}
        {requestChannelState === 'failed' && <span style={{ color: 'red', fontSize: '12px' }}>Incorrect Passcode</span>}
      </div>
    );
  } else {
    throw new Error(`Unknown channel type: ${channel.needs_passkey}, ${channel.is_curated}`);
  }
};

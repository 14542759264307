import * as React from 'react';

// Alternative resources:
// https://docs.stripe.com/billing/subscriptions/build-subscriptions?platform=react-native
// https://docs.stripe.com/payments/quickstart
// https://docs.stripe.com/api/payment_intents/object

export const PricingPage = () => {
  return (
    <stripe-pricing-table
      pricing-table-id={process.env.REACT_APP_PRICING_TABLE_ID}
      publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_LIVE_KEY}
      // TODO: query and populate the customer email here
      customer-email={'sid@lookaround.live'}
    ></stripe-pricing-table>
  );
};

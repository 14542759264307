import React, { useState } from 'react';
import { SignedInNavbar } from '../navbar/SignedInNavbar';
import { Header } from '../header/Header';
import { ResponsiveBox } from '../responsive_box/ResponsiveBox';
import { CustomButton } from '../button/CustomButton';
import { getGeolocation } from '../../api/location';
import { distance_between_locations } from '../utilities';
import { getDetailsAndNearbyExperiences } from '../../api/channel_api';

export const NavigateToExperiencePage = ({ homepageCallback, channelNavigationDetails, lookAroundChannelList }) => {
  // gettingLocation, failedToGetLocation, locationTooFar, fetchingNewChannelState, failedToFetchChannelState
  // failedToGetLocation should happen only if user refuses location access, not due to
  // location services being disabled because to get here location services need to
  // have already been enabled.
  const [processingStatus, setProcessingStatus] = useState('');

  return (
    <div>
      <SignedInNavbar homepageCallback={homepageCallback} showBrand={false} />
      <div style={{ marginTop: '5px' }}>
        <Header
          title={channelNavigationDetails.title}
          onClickFunction={() => homepageCallback({ callbackType: 'lookaround', payload: lookAroundChannelList })}
          backgroundColor='white'
        />
      </div>
      <ResponsiveBox
        isSnug={true}
        children={[
          {
            leftChild: (
              <div style={{ justifyContent: 'center', padding: '1rem' }}>
                <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                  <img
                    src={channelNavigationDetails.image}
                    alt={channelNavigationDetails.title}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: 'center',
                      borderRadius: '10px',
                      border: '1px solid gray',
                    }}
                  />
                </div>
                <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                  {channelNavigationDetails.description}
                </div>
                <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
                  <CustomButton
                    onClickFunction={() => {
                      window.open(
                        `https://maps.google.com/?q=${channelNavigationDetails.latitude},${channelNavigationDetails.longitude}`,
                        '_blank',
                        'noopener,noreferrer'
                      );
                    }}
                    button_text='Navigate'
                    type='tertiary'
                    expand={true}
                  />
                </div>
                <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                  <CustomButton
                    disabled={processingStatus === 'fetchingNewChannelState' || processingStatus === 'gettingLocation'}
                    disabledClickMessage='Fetching'
                    button_text="I'm here, start experience"
                    expand={true}
                    onClickFunction={() => {
                      // Get location
                      // Find channel: Verify user is close enough
                      // Find nearby channels and refresh that state behind the scenes
                      setProcessingStatus('gettingLocation');
                      getGeolocation()
                        .then(locationResponse => {
                          if (locationResponse.status === 'success') {
                            if (
                              distance_between_locations(
                                locationResponse.latitude,
                                locationResponse.longitude,
                                channelNavigationDetails.latitude,
                                channelNavigationDetails.longitude
                              ) < 50
                            ) {
                              setProcessingStatus('fetchingNewChannelState');
                              getDetailsAndNearbyExperiences(
                                channelNavigationDetails.latitude,
                                channelNavigationDetails.longitude,
                                channelNavigationDetails.title
                              ).then(newChannelStateResponse => {
                                if (newChannelStateResponse.statusCode === 200) {
                                  setProcessingStatus('');
                                  homepageCallback({
                                    callbackType: 'channelDetailsAndUpdatedList',
                                    payload: {
                                      channelDetails: newChannelStateResponse.details,
                                      lookaround: {
                                        local: newChannelStateResponse.experiences.channels,
                                        nearby: newChannelStateResponse.experiences.channels_nearby,
                                        serviceType: newChannelStateResponse.experiences.service_type,
                                      },
                                    },
                                  });
                                } else if (newChannelStateResponse.statusCode === 201) {
                                  setProcessingStatus('locationTooFar');
                                } else {
                                  setProcessingStatus('failedToFetchChannelState');
                                }
                              });
                            } else {
                              setProcessingStatus('locationTooFar');
                            }
                          } else {
                            setProcessingStatus('failedToGetLocation');
                          }
                        })
                        .catch(error => {
                          setProcessingStatus('failedToGetLocation');
                        });
                    }}
                  />
                </div>
                {processingStatus === 'failedToFetchChannelState' && (
                  <div
                    style={{
                      marginTop: '5px',
                      display: 'flex',
                      justifyContent: 'center',
                      textAlign: 'center',
                      fontSize: '14px',
                      color: 'maroon',
                    }}
                  >
                    Something went wrong. Please refresh the page.
                  </div>
                )}
                {processingStatus === 'failedToGetLocation' && (
                  <div
                    style={{
                      marginTop: '5px',
                      display: 'flex',
                      justifyContent: 'center',
                      textAlign: 'center',
                      fontSize: '14px',
                      color: 'maroon',
                    }}
                  >
                    Location access needed to continue, please try again.
                  </div>
                )}
                {processingStatus === 'locationTooFar' && (
                  <div
                    style={{
                      marginTop: '5px',
                      display: 'flex',
                      justifyContent: 'center',
                      textAlign: 'center',
                      fontSize: '14px',
                      color: '#DC4D01',
                    }}
                  >
                    You seem to be a bit far from this experience. Use the Navigate button to get directions and try
                    again when closer to the destination.
                  </div>
                )}
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

import React from 'react';
import { ExperienceNameButton } from './ExperienceNameButton';
import { ResponsiveBox } from '../../responsive_box/ResponsiveBox';

export const ExperienceList = ({ channels, homepageCallback }) => {
  const children_per_channel = channels.map((channel, idx) => {
    return [
      {
        leftChild: (
          <div style={{ marginBottom: '3px' }}>
            <ExperienceNameButton homepageCallback={homepageCallback} channel={channel} />
            {idx !== channels.length - 1 && (
              <hr
                style={{
                  marginTop: '0px',
                  marginBottom: '7px',
                  width: '90%',
                  borderTop: '1px solid #ccccccbb',
                  // marginLeft: 'auto',
                  marginRight: 'auto',
                  opacity: 1,
                }}
              />
            )}
          </div>
        ),
      },
    ];
  });
  const children = children_per_channel.flatMap(list => list);

  return (
    <div style={{ marginLeft: '15px', marginTop: '20px' }}>
      <ResponsiveBox children={children} isSnug={true} />
    </div>
  );
};

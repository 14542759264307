import { asyncPostRequest } from './requests';

export const fetchExperienceDetails = async experience_id => {
  // TODO: Verify details here
  return await asyncPostRequest('/experiences/experience_details', { experience_id }, true);
};

export const postLocationAccessDenied = async () => {
  return await asyncPostRequest('/event/location_access_denied', {}, true);
};

export const createExperience = async ({
  location,
  name,
  channelDescription,
  isPremium,
  thumbnailFileS3,
  references,
  pages,
}) => {
  return await asyncPostRequest(
    '/experiences/create_experience',
    {
      channel_name: name,
      channel_description: channelDescription,
      location: location,
      is_premium: isPremium,
      thumbnail_file_name_s3: thumbnailFileS3,
      references: references,
      pages: pages.map(page => {
        return {
          index: page.index,
          description: page.description,
          audio_file_name_s3: page.audioFileNameS3,
          question: page.questionText,
          question_options: page.questionOptions.map(questionOption => questionOption.text),
          correct_option_index: page.correctOptionIndex,
          correct_option_explanation: page.correctOptionExplanation,
          image_file_names_s3: page.imageFilesS3.map(imageFile => imageFile.text),
        };
      }),
    },
    true
  );
};

export const getHistoricChannels = async () => {
  return await asyncPostRequest('/channels/rated_channels', {}, true);
};

export const getExperiences = async (latitude, longitude) => {
  return await asyncPostRequest(
    '/channels/lookaround',
    {
      location: {
        latitude: latitude,
        longitude: longitude,
      },
    },
    true
  );
};

export const getPublicExperiences = async (latitude, longitude) => {
  return await asyncPostRequest(
    '/channels/lookaround_public',
    {
      location: {
        latitude: latitude,
        longitude: longitude,
      },
    },
    true
  );
};

export const getDetailsAndNearbyExperiences = async (latitude, longitude, experienceTitle) => {
  return await asyncPostRequest(
    '/experiences/details_and_nearby_experiences',
    {
      location: {
        latitude: latitude,
        longitude: longitude,
      },
      experience_title: experienceTitle,
    },
    true
  );
};

export const getExperiencePoints = async () => {
  return await asyncPostRequest('/experiences/experience_points', {}, true);
};

export const resetExperienceRatingsForAlex = async () => {
  return await asyncPostRequest('experiences/reset_experiences_for_alex', {}, true);
};

export const resetExperienceRatingsForJane = async () => {
  return await asyncPostRequest('experiences/reset_experiences_for_jane', {}, true);
};

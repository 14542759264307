import React, { useState } from 'react';
import './CreateExperienceForm.css';
import { CustomButton } from '../../button/CustomButton';
import { ResponsiveBox } from '../../responsive_box/ResponsiveBox';
import { createExperience, fetchExperienceDetails } from '../../../api/channel_api';
import { FileUpload } from '../../library/FileUpload';
import { ExperienceDetails } from '../experience_details/ExperienceDetails';
import { TileGrid } from '../tile_grid/TileGrid';

export const CreateExperienceForm = ({ homepageCallback }) => {
  const [formData, setFormData] = useState({
    name: '',
    channelDescription: '',
    location: {
      latitude: '',
      longitude: '',
    },
    isPremium: false,
    thumbnailFileS3: '',
    references: [],
    pages: [],
  });
  // TODO: Create environment variable REACT_APP_S3_BUCKET_NAME
  const awsUrlPrefix = 'https://lookaround-live.s3.amazonaws.com/';
  const [createChannelStatus, setCreateChannelStatus] = useState('initial');
  const [nameError, setNameError] = useState('');
  const [channelDescriptionError, setChannelDescriptionError] = useState('');
  const [channelLocationError, setChannelLocationError] = useState('');
  const [thumbnailUploadError, setThumbnailUploadError] = useState('');

  const isReadyToSubmit = () => {
    return (
      formData.name.length > 0 &&
      formData.name.length < 50 &&
      formData.location.latitude !== '' &&
      formData.location.longitude !== '' &&
      createChannelStatus === 'initial' &&
      nameError === '' &&
      channelDescriptionError === '' &&
      channelLocationError === '' &&
      thumbnailUploadError === '' &&
      formData.thumbnailFileS3 !== '' &&
      formData.pages.length > 0 &&
      formData.pages.every(page => page.description !== '' && page.imageFilesS3[0].text !== '') &&
      formData.pages[formData.pages.length - 1].questionText === '' &&
      formData.pages[formData.pages.length - 1].questionOptions.length === 0 &&
      formData.pages[formData.pages.length - 1].correctOptionIndex === -1 &&
      formData.pages[formData.pages.length - 1].correctOptionExplanation === '' &&
      formData.pages
        .slice(0, -1)
        .every(
          page =>
            page.questionText !== '' &&
            page.questionOptions.length > 1 &&
            page.questionOptions.every(option => option !== '') &&
            page.correctOptionIndex !== -1
        )
    );
  };

  const validateName = name => {
    if (name.length < 2) {
      return 'Channel name must be at least 2 characters long.';
    } else if (name.length > 50 || /\s{2,}/.test(name)) {
      return 'Should be between 2-50 characters, and not have consecutive spaces.';
    }
    return '';
  };

  const createExperienceSubmitAction = () => {
    setCreateChannelStatus('creating');
    createExperience({ ...formData }).then(response => {
      if (response.statusCode === 409) {
        setCreateChannelStatus('initial');
        setNameError(
          'There is another experience with the same name at that location. Please choose a different name.'
        );
      } else if (response.statusCode === 401 || response.statusCode === 400) {
        setCreateChannelStatus('initial');
        homepageCallback({ callbackType: 'signout', payload: {} });
      } else if (response.statusCode === 200) {
        // Response is OK
        homepageCallback({ callbackType: 'loading', payload: {} });
        setCreateChannelStatus('initial');
        fetchExperienceDetails(response.channel_id).then(details => {
          if (details.statusCode === 400 || details.statusCode === 404) {
            homepageCallback({ callbackType: 'signout', payload: {} });
          } else if (details.statusCode === 200) {
            homepageCallback({ callbackType: 'channelDetails', payload: details });
          } else {
            throw new Error(`Error ${details.statusCode}: Something unexpected happened`);
          }
        });
      } else {
        setCreateChannelStatus('initial');
        throw new Error(`Error ${response.statusCode}: Something unexpected happened`);
      }
    });
  };

  const handleInputChange = (e, field, subField = null) => {
    if (subField) {
      setFormData({
        ...formData,
        [field]: {
          ...formData[field],
          [subField]: e.target.value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [field]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
      });
    }
  };

  const updateLocation = (latitude, longitude) => {
    setFormData({
      ...formData,
      location: {
        latitude: latitude,
        longitude: longitude,
      },
    });
  };

  const handleLocationInput = input => {
    const regex = /^\s*-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?\s*$/;
    if (regex.test(input)) {
      setChannelLocationError('');
      const [latitude, longitude] = input.split(',').map(coord => parseFloat(coord.trim()));
      updateLocation(latitude, longitude);
    } else {
      setChannelLocationError('Expected latitude, longitude, ex: 1.23, -6.78');
    }
  };

  const addReference = () => {
    setFormData({
      ...formData,
      references: [...formData.references, { url: '', text: '' }],
    });
  };

  const updateReference = (index, field, value) => {
    const updatedReferences = [...formData.references];
    updatedReferences[index][field] = value;
    setFormData({ ...formData, references: updatedReferences });
  };

  const addPage = () => {
    setFormData({
      ...formData,
      pages: [
        ...formData.pages,
        {
          index: formData.pages.length,
          description: '',
          audioFileS3: '',
          questionText: '',
          questionOptions: [],
          correctOptionIndex: -1,
          correctOptionExplanation: '',
          imageFilesS3: [
            {
              url: '',
              text: '',
              index: '',
            },
          ],
        },
      ],
    });
  };

  const updatePage = (index, field, value) => {
    const updatedPages = [...formData.pages];
    updatedPages[index][field] = value;
    setFormData({ ...formData, pages: updatedPages });
  };

  const addQuestionOption = pageIndex => {
    const updatedPages = [...formData.pages];
    updatedPages[pageIndex].questionOptions.push({ index: formData.pages[pageIndex].questionOptions.length, text: '' });
    setFormData({ ...formData, pages: updatedPages });
  };

  const updateQuestionOption = (pageIndex, index, value) => {
    const updatedPages = [...formData.pages];
    updatedPages[pageIndex].questionOptions[index].text = value;
    setFormData({ ...formData, pages: updatedPages });
  };

  const handleThumbnailUpload = response => {
    response.status === 200
      ? setFormData({ ...formData, thumbnailFileS3: response.filename })
      : setThumbnailUploadError(response.message);
  };

  const handleAudioUpload = (response, pageIndex) => {
    if (response.status === 200) {
      updateAudioFileName(pageIndex, response.filename);
    } else {
      console.log(`Audio update failed for page ${pageIndex}: ${response.message}`);
    }
  };

  const addImageFileName = pageIndex => {
    const updatedPages = [...formData.pages];
    updatedPages[pageIndex].imageFilesS3.push({
      url: '',
      text: '',
      index: '',
    });
    setFormData({ ...formData, pages: updatedPages });
  };

  const handlePageImageUpload = (response, pageIndex, fileIndex) => {
    if (response.status === 200) {
      updateImageFileName(pageIndex, fileIndex, response.filename);
    } else {
      console.log(`Upload failed for page ${pageIndex}, file ${fileIndex}: ${response.message}`);
    }
  };

  const updateImageFileName = (pageIndex, fileIndex, value) => {
    const updatedPages = [...formData.pages];
    updatedPages[pageIndex].imageFilesS3[fileIndex] = {
      url: `${awsUrlPrefix}${value}`,
      text: value,
      index: fileIndex,
    };
    setFormData({ ...formData, pages: updatedPages });
  };

  const updateAudioFileName = (pageIndex, value) => {
    const updatedPages = [...formData.pages];
    updatedPages[pageIndex].audioFileS3 = value;
    setFormData({ ...formData, pages: updatedPages });
  };

  const handleSubmit = e => {
    e.preventDefault();
    createExperienceSubmitAction();
  };

  const formChildren = [
    {
      leftChild: (
        <label
          style={{
            display: 'block',
            marginBottom: '5px',
            fontWeight: 'bold',
            marginLeft: '10px',
          }}
        >
          Thumbnail picture
        </label>
      ),
      rightChild: <div></div>,
    },
    {
      leftChild: (
        <div style={{ marginLeft: '10px' }}>
          <div style={{ fontSize: '12px' }}>{formData.thumbnailFileS3}</div>
          <FileUpload processUpload={handleThumbnailUpload} />
          {thumbnailUploadError && (
            <div style={{ color: '#B00000', fontSize: '12px', marginBottom: '5px' }}>{thumbnailUploadError}</div>
          )}
        </div>
      ),
    },
    {
      leftChild: (
        <label
          htmlFor='name'
          style={{
            display: 'block',
            marginBottom: '5px',
            fontWeight: 'bold',
            marginLeft: '10px',
          }}
        >
          Thumbnail title
        </label>
      ),
      rightChild: <div></div>,
    },
    {
      leftChild: (
        <div style={{ marginBottom: '10px', marginLeft: '10px' }}>
          <input
            type='text'
            id='name'
            value={formData.name}
            onChange={e => handleInputChange(e, 'name')}
            className='italic-placeholder'
            onBlur={() => {
              validateName(formData.name);
            }}
            style={{
              width: '100%',
              padding: '5px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              boxSizing: 'border-box',
              fontSize: '14px',
            }}
            placeholder='Up to 50 characters'
          />
          {nameError && <div style={{ color: '#B00000', fontSize: '12px', marginBottom: '5px' }}>{nameError}</div>}
        </div>
      ),
    },
    {
      leftChild: (
        <label
          htmlFor='channelDescription'
          style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold', marginLeft: '10px' }}
        >
          Thumbnail description
        </label>
      ),
      rightChild: <div></div>,
    },
    {
      leftChild: (
        <div style={{ marginBottom: '10px', marginLeft: '10px' }}>
          <textarea
            id='channelDescription'
            value={formData.channelDescription}
            onChange={e => handleInputChange(e, 'channelDescription')}
            className='italic-placeholder'
            onBlur={() => {
              if (formData.channelDescription.length > 280) {
                setChannelDescriptionError('Description exceeds 280 characters limit.');
              }
            }}
            style={{
              width: '100%',
              padding: '5px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              boxSizing: 'border-box',
              fontSize: '14px',
              fontFamily: 'inherit',
            }}
            placeholder='Optional, up to 280 characters'
          />
          {channelDescriptionError && (
            <div style={{ color: '#B00000', fontSize: '12px', marginBottom: '5px' }}>{channelDescriptionError}</div>
          )}
        </div>
      ),
    },
    {
      leftChild: (
        <label
          style={{
            display: 'block',
            marginBottom: '5px',
            fontWeight: 'bold',
            marginLeft: '10px',
          }}
        >
          Location
        </label>
      ),
      rightChild: <div></div>,
    },
    {
      leftChild: (
        <div style={{ marginBottom: '10px', marginLeft: '10px' }}>
          <input
            type='text'
            id='channelLocation'
            onChange={e => handleLocationInput(e.target.value)}
            className='italic-placeholder'
            onBlur={() => {
              if (formData.location.latitude.toString().length + formData.location.longitude.toString().length > 50) {
                setChannelLocationError('Decrease precision of location coordinates.');
              }
            }}
            placeholder='Latitude, longitude, ex. "1.23, -4.56"'
            style={{
              width: '100%',
              padding: '5px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              boxSizing: 'border-box',
              fontSize: '14px',
            }}
          />
          {channelLocationError && (
            <div style={{ color: '#B00000', fontSize: '12px', marginBottom: '5px' }}>{channelLocationError}</div>
          )}
        </div>
      ),
    },
    {
      leftChild: (
        <label
          style={{
            display: 'block',
            marginBottom: '5px',
            fontWeight: 'bold',
            marginLeft: '10px',
          }}
        >
          Nominate for premium
        </label>
      ),
      rightChild: (
        <input
          type='checkbox'
          checked={formData.isPremium}
          onChange={e => handleInputChange(e, 'isPremium')}
          style={{ marginRight: '5rem' }}
        />
      ),
    },
    {
      leftChild: (
        <label
          style={{
            display: 'block',
            marginBottom: '5px',
            fontWeight: 'bold',
            marginLeft: '10px',
          }}
        >
          References
        </label>
      ),
      rightChild: <div></div>,
    },
    {
      leftChild: (
        <div style={{ marginLeft: '10px' }}>
          {formData.references.map((reference, index) => (
            <div
              key={index}
              style={{
                display: 'block',
                marginBottom: '5px',
                marginLeft: '10px',
              }}
            >
              <input
                type='text'
                placeholder='Text'
                className='italic-placeholder'
                value={reference.text}
                onChange={e => updateReference(index, 'text', e.target.value)}
                style={{
                  width: '100%',
                  padding: '5px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  boxSizing: 'border-box',
                  marginBottom: '3px',
                }}
              />
              <input
                type='text'
                onChange={e => updateReference(index, 'url', e.target.value)}
                className='italic-placeholder'
                value={reference.url}
                style={{
                  width: '100%',
                  padding: '5px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  boxSizing: 'border-box',
                }}
                placeholder='URL'
              />
              <hr />
            </div>
          ))}
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <CustomButton
              button_text='Add another reference'
              onClickFunction={addReference}
              type='tertiary'
              size='small'
            />
          </div>
        </div>
      ),
    },
    {
      leftChild: (
        <label
          style={{
            display: 'block',
            marginBottom: '5px',
            fontWeight: 'bold',
            marginLeft: '10px',
            marginTop: '10px',
          }}
        >
          Pages
        </label>
      ),
      rightChild: <div></div>,
    },
    {
      leftChild: (
        <div>
          {formData.pages.map((page, pageIndex) => (
            <div key={pageIndex}>
              <label
                style={{
                  display: 'block',
                  marginBottom: '5px',
                  fontWeight: 'bold',
                  marginLeft: '10px',
                  marginTop: '10px',
                }}
              >
                Page {page.index + 1}
              </label>
              <label
                style={{
                  display: 'block',
                  marginBottom: '5px',
                  marginLeft: '10px',
                  marginTop: '10px',
                }}
              >
                Description
              </label>
              <textarea
                value={page.description}
                onChange={e => updatePage(pageIndex, 'description', e.target.value)}
                className='italic-placeholder'
                style={{
                  width: '100%',
                  padding: '5px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  boxSizing: 'border-box',
                  fontSize: '14px',
                  fontFamily: 'inherit',
                  marginBottom: '10px',
                  marginLeft: '10px',
                }}
                placeholder='Optional, up to 280 characters'
              />
              <label
                style={{
                  display: 'block',
                  marginBottom: '5px',
                  marginLeft: '10px',
                  marginTop: '10px',
                }}
              >
                Audio file
              </label>
              <div style={{ marginLeft: '10px' }}>
                <div style={{ fontSize: '12px' }}>{page.audioFileS3.split('_').slice(1).join('_')}</div>
                <FileUpload processUpload={response => handleAudioUpload(response, pageIndex)} />
              </div>
              <label
                style={{
                  display: 'block',
                  marginBottom: '5px',
                  marginLeft: '10px',
                  marginTop: '10px',
                }}
              >
                Question
              </label>
              <input
                type='text'
                placeholder='Question'
                className='italic-placeholder'
                value={page.questionText}
                onChange={e => updatePage(pageIndex, 'questionText', e.target.value)}
                style={{
                  width: '100%',
                  padding: '5px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  boxSizing: 'border-box',
                  marginBottom: '3px',
                  marginLeft: '10px',
                }}
              />
              <label
                style={{
                  display: 'block',
                  marginBottom: '5px',
                  marginLeft: '10px',
                  marginTop: '10px',
                }}
              >
                Question Options
              </label>
              <div style={{ marginLeft: '10px' }}>
                {page.questionOptions.map(({ index, text }) => (
                  <div
                    key={index}
                    style={{
                      display: 'block',
                      marginBottom: '5px',
                    }}
                  >
                    <input
                      type='text'
                      placeholder={`Option ${index}`}
                      className='italic-placeholder'
                      value={text}
                      onChange={e => updateQuestionOption(pageIndex, index, e.target.value)}
                      style={{
                        width: '100%',
                        padding: '5px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                        boxSizing: 'border-box',
                        marginBottom: '3px',
                        color: `${page.correctOptionIndex === index ? 'green' : 'black'}`,
                      }}
                    />
                  </div>
                ))}
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <CustomButton
                    button_text='Add another option'
                    onClickFunction={() => addQuestionOption(pageIndex)}
                    type='tertiary'
                    size='small'
                  />
                </div>
              </div>
              <label
                style={{
                  display: 'block',
                  marginBottom: '5px',
                  marginLeft: '10px',
                  marginTop: '10px',
                }}
              >
                Correct Option Index
              </label>
              <input
                type='number'
                value={page.correctOptionIndex}
                onChange={e => updatePage(pageIndex, 'correctOptionIndex', parseInt(e.target.value))}
                min='-1'
                max={`${page.questionOptions.length - 1}`}
                style={{
                  width: '50%',
                  padding: '5px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  boxSizing: 'border-box',
                  marginBottom: '3px',
                  marginLeft: '10px',
                  color: `${page.correctOptionIndex >= 0 && page.correctOptionIndex < page.questionOptions.length ? 'black' : 'red'}`,
                }}
              />
              <label
                style={{
                  display: 'block',
                  marginBottom: '5px',
                  marginLeft: '10px',
                  marginTop: '10px',
                }}
              >
                Correct Option Explanation
              </label>

              <textarea
                value={page.correctOptionExplanation}
                onChange={e => updatePage(pageIndex, 'correctOptionExplanation', e.target.value)}
                className='italic-placeholder'
                style={{
                  width: '100%',
                  padding: '5px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  boxSizing: 'border-box',
                  fontSize: '14px',
                  fontFamily: 'inherit',
                  marginBottom: '10px',
                  marginLeft: '10px',
                }}
                placeholder='Optional, up to 280 characters'
              />

              <label
                style={{
                  display: 'block',
                  marginBottom: '5px',
                  marginLeft: '10px',
                  marginTop: '10px',
                }}
              >
                Images on page
              </label>

              <div>
                {page.imageFilesS3.map((fileDetails, fileIndex) => (
                  <div key={fileIndex} style={{ marginLeft: '10px' }}>
                    <div style={{ fontSize: '12px' }}>{fileDetails.text.split('_').slice(1).join('_')}</div>
                    <FileUpload processUpload={response => handlePageImageUpload(response, pageIndex, fileIndex)} />
                  </div>
                ))}
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <CustomButton
                    button_text='Add another image'
                    onClickFunction={() => addImageFileName(pageIndex)}
                    type='tertiary'
                    size='small'
                  />
                </div>
              </div>
            </div>
          ))}
          <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
            <CustomButton button_text='Add another page' onClickFunction={addPage} type='secondary' size='small' />
          </div>
        </div>
      ),
    },
    {
      leftChild: (
        <div>
          <hr />
          <label
            style={{
              display: 'block',
              marginBottom: '5px',
              marginLeft: '10px',
              marginTop: '10px',
              fontWeight: 'bold',
            }}
          >
            Thumbnail preview
          </label>
          <TileGrid
            homepageCallback={homepageCallback}
            tiles={[
              {
                channelName: formData.name,
                thumbnailUrl: `${awsUrlPrefix}${formData.thumbnailFileS3}`,
                description: formData.channelDescription,
                size: 'big',
                channelId: '',
                homepageCallback: homepageCallback,
                isPremium: formData.isPremium,
              },
              {
                channelName: formData.name,
                thumbnailUrl: `${awsUrlPrefix}${formData.thumbnailFileS3}`,
                description: formData.channelDescription,
                size: 'small',
                channelId: '',
                homepageCallback: homepageCallback,
                isPremium: formData.isPremium,
              },
            ]}
          />
          <hr />
        </div>
      ),
    },
    {
      leftChild:
        formData.pages.length > 0 ? (
          <div>
            <label
              style={{
                display: 'block',
                marginBottom: '5px',
                marginLeft: '10px',
                marginTop: '10px',
                fontWeight: 'bold',
              }}
            >
              Details preview
            </label>
            <ResponsiveBox
              children={[
                {
                  leftChild: (
                    <ExperienceDetails
                      channelDetails={formData}
                      homepageCallback={homepageCallback}
                      backButtonFunction={() => {}}
                    />
                  ),
                },
              ]}
            />
            <hr />
          </div>
        ) : (
          <div>
            <label
              style={{
                display: 'block',
                marginBottom: '5px',
                marginLeft: '10px',
                marginTop: '10px',
                fontWeight: 'bold',
              }}
            >
              Details preview
            </label>
            <div style={{ marginLeft: '10px' }}>
              Once pages are added, the details of the experience will appear here
            </div>
            <hr />
          </div>
        ),
    },
    {
      leftChild: (
        <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
          <CustomButton
            button_text='Create'
            backgroundColor='#2E8B57'
            onClickFunction={() => {}}
            type='primary'
            size='small'
            isSubmit={true}
            disabled={!isReadyToSubmit()}
          />
        </div>
      ),
    },
    {
      leftChild: (
        <div style={{ color: 'red', marginTop: '5px', fontSize: '10px', display: 'flex', justifyContent: 'center' }}>
          {!isReadyToSubmit() && 'Populate required fields to submit'}
        </div>
      ),
    },
  ];

  if (createChannelStatus === 'creating') {
    return (
      <ResponsiveBox
        isSnug={true}
        children={[{ leftChild: <p style={{ textAlign: 'center' }}>Creating channel...</p> }]}
      />
    );
  } else {
    return (
      <form onSubmit={handleSubmit}>
        <ResponsiveBox children={formChildren} isSnug={true} />
      </form>
    );
  }
};

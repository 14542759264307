import React, { useState } from 'react';
import { fetchExperienceDetails } from '../../../api/channel_api';

export const ExperienceNameButton = ({ homepageCallback, channel }) => {
  const [retrieveChannelDetailsStatus, setRetrieveChannelDetailsStatus] = useState('initial');

  return (
    <div>
      {retrieveChannelDetailsStatus === 'initial' && (
        <button
          style={{
            background: 'none',
            color: '#1565C0',
            border: 'none',
            padding: '0',
            font: 'inherit',
            cursor: 'pointer',
            textAlign: 'left',
            fontWeight: 700,
            width: '100%',
          }}
          onClick={() => {
            setRetrieveChannelDetailsStatus('loading');
            fetchExperienceDetails(channel.channelId).then(details => {
              if (details.statusCode === 400 || details.statusCode === 404) {
                homepageCallback({ callbackType: 'signout', payload: {} });
              } else if (details.statusCode === 200) {
                homepageCallback({ callbackType: 'channelDetails', payload: details });
              } else {
                throw new Error(`Error ${details.statusCode}: Something unexpected happened`);
              }
            });
            setRetrieveChannelDetailsStatus('success');
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={channel.thumbnailUrl}
              alt='Thumbnail'
              style={{
                marginRight: '10px',
                borderRadius: '4px',
                // border: '1px solid gray',
                width: '40px',
                height: '40px',
              }}
            />
            <span style={{ fontSize: '18px' }}>{channel.channelName} </span>
          </div>
          {/* <hr style={{marginRight: '30px'}}/> */}
        </button>
      )}
      {retrieveChannelDetailsStatus === 'loading' && <div>Looking up the experience...</div>}
      {retrieveChannelDetailsStatus === 'success' && <div>Going to the experience...</div>}
      {retrieveChannelDetailsStatus === 'error' && <div>Error loading experience</div>}
    </div>
  );
};

import { GoogleLogin } from '@react-oauth/google';
import { FindNearbyChannels } from '../../find_channels/FindNearbyChannels';
import { LookAroundNavbar } from '../../navbar/LookAroundNavbar';
import { SignedInNavbar } from '../../navbar/SignedInNavbar';
import { ResponsiveBox } from '../../responsive_box/ResponsiveBox';
import { ExperienceList } from '../experience_list/ExperienceList';
import { nearServiceAreaMessage, outsideServiceAreaMessage } from '../service_areas/MessageByArea';
import { TileGrid } from '../tile_grid/TileGrid';
import { LookAroundPageProgressBar } from '../user_progress_bar/ProgressBarGenerator';
import { CardCarousel } from '../../hotspots/CardCarousel';

export const LookAroundPage = ({
  homepageCallback,
  lookAroundChannelList,
  signInStatus,
  verifyToken,
  onSignInFailure,
  userExperienceCount = 0,
  username = '',
  totalExperienceCount = 0,
}) => {
  let localIncompleteExperiences = lookAroundChannelList.local ? lookAroundChannelList.local : [];
  let nearbyIncompleteExperiences = lookAroundChannelList.nearby ? lookAroundChannelList.nearby : [];
  let localCompleteExperiences = [];
  let nearbyCompleteExperiences = [];
  if (signInStatus === 'signedIn') {
    if (lookAroundChannelList.local) {
      localIncompleteExperiences = lookAroundChannelList.local.filter(channel => channel.rating === null);
      localCompleteExperiences = lookAroundChannelList.local.filter(channel => channel.rating !== null);
    }
    if (lookAroundChannelList.nearby) {
      nearbyIncompleteExperiences = lookAroundChannelList.nearby.filter(channel => channel.rating === null);
      nearbyCompleteExperiences = lookAroundChannelList.nearby.filter(channel => channel.rating !== null);
    }
  }

  return (
    <div>
      {signInStatus === 'signedIn' ? (
        <SignedInNavbar homepageCallback={homepageCallback} showBrand={false} />
      ) : (
        <LookAroundNavbar showBrand={false} />
      )}
      {signInStatus === 'signedOut' && (
        <div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', fontWeight: 'bold' }}>
            Sign in to get started
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: '20px' }}>
            <GoogleLogin onSuccess={verifyToken} onError={onSignInFailure} text='continue_with' />
          </div>
        </div>
      )}
      <ResponsiveBox
        isSnug={true}
        children={[
          {
            // Messaging based on user location type.
            leftChild:
              lookAroundChannelList.serviceType === 'out' ? (
                <div style={{ marginTop: '20px' }}>
                  <h1 style={{ fontWeight: 'bold', paddingLeft: '20px' }}> Hi {username}</h1>
                  <h4
                    style={{
                      marginBottom: '40px',
                      display: 'flex',
                      justifyContent: 'left',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                    }}
                  >
                    {outsideServiceAreaMessage}
                  </h4>
                </div>
              ) : lookAroundChannelList.serviceType === 'near' ? (
                <div style={{ marginTop: '20px' }}>
                  <h1 style={{ fontWeight: 'bold', paddingLeft: '20px' }}> Hi {username}</h1>
                  <h4
                    style={{
                      marginBottom: '40px',
                      display: 'flex',
                      justifyContent: 'left',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                    }}
                  >
                    {nearServiceAreaMessage}
                  </h4>
                </div>
              ) : (
                <></>
              ),
          },
          {
            leftChild:
              lookAroundChannelList.serviceType !== 'out' ? (
                <div
                  style={{
                    width: '100%',
                    paddingLeft: '20px',
                    marginTop: '20px',
                    justifyContent: 'center',
                    verticalAlign: 'center',
                  }}
                >
                  <h1 style={{ fontWeight: 'bold' }}>Around you</h1>
                </div>
              ) : (
                <></>
              ),
            rightChild:
              lookAroundChannelList.serviceType !== 'out' ? (
                <FindNearbyChannels homepageCallback={homepageCallback} buttonText='LookAround again' size='small' />
              ) : (
                <></>
              ),
            alignNaturally: true,
          },
          {
            // Local incomplete experiences (those 'around' a user)
            leftChild:
              localIncompleteExperiences.length > 0 ? (
                <div>
                  <div
                    style={{
                      width: '100%',
                      paddingLeft: '20px',
                      justifyContent: 'center',
                      lineHeight: 1,
                    }}
                  >
                    <h4>
                      {signInStatus === 'signedIn'
                        ? 'Tap to start these experiences'
                        : 'Sign in to start these experiences'}{' '}
                    </h4>
                  </div>
                  <TileGrid tiles={localIncompleteExperiences} homepageCallback={homepageCallback} />
                </div>
              ) : lookAroundChannelList.serviceType !== 'out' ? (
                <div
                  style={{
                    width: '100%',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    marginTop: '10px',
                  }}
                >
                  <ResponsiveBox
                    backgroundColor='#dddddd'
                    children={[
                      {
                        leftChild: (
                          <div style={{ textAlign: 'center' }}>
                            <img
                              src='https://lookaround-live.s3.us-west-1.amazonaws.com/ba14432ab5df4ab2a80e2afce2aafe4e_round.png'
                              height='50px'
                              width='50px'
                              alt='Person walking around'
                            />
                          </div>
                        ),
                      },
                      {
                        leftChild: (
                          <div
                            style={{
                              width: '100%',
                              textAlign: 'center',
                            }}
                          >
                            To discover new stories, tap 'LookAround' from a different location or choose one from
                            below.
                          </div>
                        ),
                      },
                    ]}
                  />
                </div>
              ) : (
                <></>
              ),
          },
          {
            leftChild:
              signInStatus === 'signedIn' ? (
                <LookAroundPageProgressBar
                  userExperienceCount={userExperienceCount}
                  totalExperienceCount={totalExperienceCount}
                  serviceType={lookAroundChannelList.serviceType}
                />
              ) : (
                <></>
              ),
          },
          {
            // nearby incomplete experiences
            leftChild:
              nearbyIncompleteExperiences.length > 0 ? (
                <div>
                  <div
                    style={{
                      width: '100%',
                      paddingLeft: '20px',
                      marginTop: '20px',
                      justifyContent: 'center',
                      lineHeight: 1,
                    }}
                  >
                    <h1 style={{ fontWeight: 'bold' }}>
                      Short {lookAroundChannelList.serviceType === 'in' ? 'walk' : 'distance'} away
                    </h1>
                    <h4>Navigate to these experiences</h4>
                  </div>
                  <TileGrid tiles={nearbyIncompleteExperiences} homepageCallback={homepageCallback} />
                </div>
              ) : (
                <></>
              ),
          },
          {
            leftChild:
              lookAroundChannelList.hotspots && lookAroundChannelList.hotspots.length > 0 ? (
                <div>
                  <div
                    style={{
                      width: '100%',
                      paddingLeft: '20px',
                      marginTop: '40px',
                      justifyContent: 'center',
                      lineHeight: 1,
                      paddingBottom: '10px',
                    }}
                  >
                    <h1 style={{ fontWeight: 'bold' }}>Explore another perspective</h1>
                    <h4>Navigate to these locations to discover stories that follow unique themes</h4>
                  </div>
                  <CardCarousel cards={lookAroundChannelList.hotspots} />
                </div>
              ) : (
                <></>
              ),
          },
          {
            // Completed experiences
            leftChild:
              localCompleteExperiences.length + nearbyCompleteExperiences.length > 0 ? (
                <div>
                  <div
                    style={{
                      width: '100%',
                      paddingLeft: '20px',
                      marginTop: '10px',
                      justifyContent: 'center',
                      lineHeight: 1,
                    }}
                  >
                    <h1 style={{ fontWeight: 'bold' }}>Previously visited</h1>
                    <h4>You completed these nearby experiences</h4>
                  </div>
                  <ExperienceList
                    channels={[...localCompleteExperiences, ...nearbyCompleteExperiences]}
                    homepageCallback={homepageCallback}
                  />
                </div>
              ) : (
                <></>
              ),
          },
        ]}
      />
    </div>
  );
};

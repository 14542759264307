import './SignedInLandingPage.css';
import React, { useState } from 'react';
import Slider from 'react-slick';
import { ResponsiveBox } from '../../responsive_box/ResponsiveBox';
import { FindNearbyChannels } from '../../find_channels/FindNearbyChannels';
import { FindHistoricChannels } from '../../find_channels/FindHistoricChannels';
import { LandingPageProgressBar } from '../../experiences/user_progress_bar/ProgressBarGenerator';

const imageNames = [
  '1_MarkHopkins.jpg',
  '2_KoiFish.jpg',
  '3_CityLights.jpg',
  '4_Swensen.jpg',
  '5_MrJiu.jpg',
  '6_Coverage.jpg',
];
const imageCaptions = [
  'Learn about San Franciscan neighborhoods in 3 simple steps...',
  'Step 1- See something intriguing? Tap LookAround',
  'Step 2- Discover stories close to you curated from trusted sources.',
  'Step 3- Repeat. Get your personal SF storyboard!',
  <>
    Want to share your story? Connect with us at <a href='mailto:hello@lookaround.live'>hello@lookaround.live</a>
  </>,
  'Currently serving Russian Hill, Nob Hill, Chinatown, North Beach and Telegraph Hill.',
];

export const SignedInLandingPage = ({ homepageCallback, username, experienceCount, handleUserEvent }) => {
  const lookAroundBoxChildren = [
    {
      leftChild: (
        <div>
          <FindNearbyChannels homepageCallback={homepageCallback} handleUserEvent={handleUserEvent} />
          <div style={{ textAlign: 'center', marginTop: '8px', fontSize: '12px' }}>
            📍LookAround will request for location access to find channels.
          </div>
        </div>
      ),
    },
  ];

  const [imageIndex, setImageIndex] = useState(0);

  const sliderSettings = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: (current, next) => setImageIndex(next),
  };

  return (
    <div>
      <ResponsiveBox
        isSnug={true}
        children={[
          {
            leftChild: <div style={{ paddingLeft: '5px' }}>Welcome, {username}</div>,
            rightChild: experienceCount > 0 ? <FindHistoricChannels homepageCallback={homepageCallback} /> : <></>,
            leftWidth: '70%',
            alignNaturally: true,
          },
          {
            leftChild: <LandingPageProgressBar userExperienceCount={experienceCount} totalExperienceCount={110} />,
          },
          {
            rightChild: (
              <div
                style={{
                  paddingLeft: '0px',
                  paddingRight: '10px',
                  paddingTop: '30px',
                  textAlign: 'center',
                  justifyContent: 'center',
                }}
              >
                <Slider {...sliderSettings}>
                  {imageNames.map((img, idx) => {
                    return (
                      <div
                        key={idx}
                        className={
                          idx === imageIndex
                            ? 'slideSignedInLanding activeSlideSignedInLanding'
                            : idx === imageIndex - 1 || idx === imageNames.length + imageIndex - 1
                              ? 'slideSignedInLanding previousSlideSignedInLanding'
                              : 'slideSignedInLanding'
                        }
                      >
                        <img
                          src={'https://lookaround-live.s3.us-west-1.amazonaws.com/static/' + img}
                          alt={img}
                          height='230px'
                          style={{ borderRadius: '10px' }}
                        />
                        {idx === imageIndex && (
                          <div
                            style={{
                              textAlign: 'center',
                              marginTop: '5px',
                              width: '230px',
                              height: '75px',
                              fontWeight: 'bold',
                            }}
                          >
                            {imageCaptions[idx]}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </Slider>
              </div>
            ),
          },
        ]}
      />
      <div style={{ marginTop: '15px' }}>
        <ResponsiveBox isSnug={true} children={lookAroundBoxChildren} />
      </div>
    </div>
  );
};
